import React from 'react';
import tw, {styled} from 'twin.macro';
import data from '../../../config/site_data.json';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Container} from '../../Grid';
import {Text} from '../../Typography';
import {Button} from '../../Button';

const Wrapper = styled.section`
  ${tw``}
  background-color: #f3f8f9;
  padding-top: 167px;
  padding-bottom: 101px;
`;

const Content = styled.div`
  ${tw`flex flex-col md:flex-row justify-between md:items-center`}

  @media ${props => props.theme.screens.md} {
    div {
      flex-basis: 80%;
    }
  }
  @media ${props => props.theme.screens.xl} {
    div {
      /* flex-basis: 567px; */
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const Heading = styled.h1`
  ${tw`text-secondary text-center`}
  margin-bottom: 22px;

  @media ${props => props.theme.screens.md} {
    ${tw`text-left`}
    font-size: 45px;
    line-height: 56px;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 50px;
    line-height: 64px;
  }

  @media ${props => props.theme.screens.xl} {
    margin-right: 42px;
    margin-bottom: 0;
  }

  font-size: 30px;
  line-height: 42px;
`;
const StyledText = styled(Text)`
  ${tw`text-secondary text-center font-medium`}

  font-size: 15px;
  line-height: 24px;

  a {
    color: #039259;
    text-decoration: underline;
  }

  @media ${props => props.theme.screens.md} {
    ${tw`text-left`}
    font-size: 21px;
    line-height: 32px;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 21px;
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  margin-top: 45px;

  @media ${props => props.theme.screens.md} {
    font-size: 21px;
    line-height: 139.02%;
    height: 68px;
  }

  @media ${props => props.theme.screens.xl} {
    margin-top: 0px;
  }
`;

const Banner = () => {
  const {t} = useTranslation();
  const {heading, text} = data.prices;

  return (
    <Wrapper>
      <Container>
        <Content>
          <div>
            <Heading>{t(heading)}</Heading>
            <StyledText dangerouslySetInnerHTML={{ __html: t(text)}} />
          </div>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Banner;
