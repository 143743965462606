import React, {useEffect, useState, useContext} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import tw, {styled, theme} from 'twin.macro';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import SVGIcon from '../../SVGIcon';
import useWindowSize from '../../../hooks/useWindowSize';
import {NotesHeading, Notes} from './Menu';
import {TranslateUrlsContext} from 'gatsby-plugin-translate-urls';
import data from '../../../config/site_data.json';

const UpholsteryWrapper = styled.div``;

const ItemWrapper = styled.div`
  ${tw`md:grid md:grid-cols-2 gap-x-4 xl:grid-cols-3`}
`;

const Item = styled.div`
  ${tw`flex items-center p-2 mb-4`}
  background-color: #f6f7f6;
  border-radius: 17px;
`;

const Info = styled.div`
  ${tw`ml-4`}
`;

const Title = styled.h4`
  ${tw`text-secondary`}

  @media ${props => props.theme.screens.md} {
    font-size: 24px;
    line-height: 32px;
  }
`;

const Description = styled.span`
  ${tw`text-200`}

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 140%;
  }
`;

const Picture = styled.picture`
  max-width: 141px;
  max-height: 95px;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  overflow: hidden;

  background: white;

  @media ${props => props.theme.screens.md} {
    max-width: 156px;
  }
`;

const Seemore = styled.span`
  ${tw`flex justify-end items-center text-200 mt-8 md:hidden`}

  svg {
    margin-left: 14px;
  }

  font-size: 17px;
  line-height: 123%;
  letter-spacing: -0.01em;
`;

const ITEMS_INCREMENT = 4;

const ImageWrapper = styled.div`
  background-color: #fff;
  width: 160px;
  height: 93px;
  border-radius: 10px;
  padding: 6px;
`;

const Space = styled.div`
  width: 165px;
`;

const UpholsteryList = () => {
  const {t} = useTranslation();
  const {locale} = useContext(TranslateUrlsContext);

  const size = useWindowSize();

  const {list} = useStaticQuery(graphql`
    query {
      list: allContentfulItems(sort: {order: ASC, fields: createdAt}) {
        edges {
          node {
            amount
            id
            node_locale
            unit
            title
            thumbnail {
              gatsbyImageData(
                width: 150
                quality: 90
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  const items = list.edges.filter(({node}: any) =>
    node.node_locale.includes(locale),
  );

  const totalItems = items.length;

  const [limit, setLimit] = useState(ITEMS_INCREMENT);
  const [seeLess, setSeeless] = useState(false);

  useEffect(() => {
    if (limit >= totalItems) {
      setSeeless(true);
    } else {
      setSeeless(false);
    }
  }, [limit]);

  useEffect(() => {
    const breakpoint = theme('screens.md').replace('px', '');

    if (size.width >= breakpoint) {
      setLimit(totalItems);
    }
  }, [size]);

  const handleSeeMore = () => {
    if (seeLess) {
      setLimit(ITEMS_INCREMENT);
      return;
    }
    setLimit(limit + ITEMS_INCREMENT);
  };
  const upholsteryList = t('upholstery_notes_list').split('|');
  return (
    <UpholsteryWrapper>
      <ItemWrapper>
        {items.map(({node}: any, index: number) => {
          if (index + 1 > limit) return null;
          return (
            <Item key={node.id}>
              <Space>
                <ImageWrapper>
                  <GatsbyImage
                    image={getImage(node.thumbnail)}
                    style={{height: 85}}
                    imgStyle={{objectFit: 'contain'}}
                  />
                </ImageWrapper>
              </Space>

              <Info>
                <Title>{t(node.title)}</Title>
                <Description>
                  {t(node.amount)} {t(node.unit)}
                </Description>
              </Info>
            </Item>
          );
        })}
      </ItemWrapper>

      <Seemore onClick={handleSeeMore}>
        {seeLess ? (
          <>
            {t('see_less')}
            <SVGIcon icon="regular-chevron-up" />{' '}
          </>
        ) : (
          <>
            {t('see_more')}
            <SVGIcon icon="regular-chevron-down" />{' '}
          </>
        )}
      </Seemore>
      <div style={{marginTop: 30}}>
        <NotesHeading>{t('upholstery_notes_heading')}</NotesHeading>
        <Notes>
          {upholsteryList.map((note: any) => (
            <li key={note}> {note} </li>
          ))}
        </Notes>
      </div>
    </UpholsteryWrapper>
  );
};

export default UpholsteryList;
